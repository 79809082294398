import React from "react";
import { Row, theme } from "antd";
import "react-phone-input-2/lib/style.css";
import "../css/Home.css";
import HouseIcon from "@mui/icons-material/House";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import OfferList from "./widgets/OfferList";


const offers = [
    {
        icon: <LocalTaxiIcon/>,
        offerHeading: 'Hassle-Free Airport Pickup',
        offerDetails: "Professional drivers to welcome students upon arrival and transport them safely to their accommodations. With Real-Time Flight Tracking, we ensure punctual pickups regardless of flight delays.",
    },
    {
        icon: <HouseIcon/>,
        offerHeading: 'Clean Accommodations',
        offerDetails: "Partnerships with reputable housing providers to ensure high standards of cleanliness and safety. Accommodations located within convenient distance to major universities and colleges.",
    },
    {
        icon: <RestaurantIcon/>,
        offerHeading: 'Preferred Meal Choices',
        offerDetails: "Regular delivery of fresh, nutritious meals to students' accommodations. We offer specially curated Indian meals with options to experience local Canadian dishes.",
    },
    {
      icon: <SupportAgentIcon/>,
      offerHeading: 'Customer Support',
      offerDetails: " Our round-the-clock helpline offers immediate assistance for any emergencies or urgent concerns in Calgary. Whether it's a health issue, a housing problem, or general inquiries, our support team is always available to help.",
  }
]
const WhatWeOffer = () => {
  const { token } = theme.useToken();

  return (
    <div
      style={{
        backgroundColor: token.fontColorLight,
        paddingBottom: '4%'
      }}
    >
      <div
        style={{
          color: token.fontColorDark,
          paddingTop: "60px",
          margin: "0 20px",
        }}
      >
        <Row justify="center">
          <h1 style={{ fontSize: token.fontSizeHeading1, margin: 0 }}>
            What We Offer
          </h1>
        </Row>
        <Row justify="center">
          <h3 style={{ fontSize: token.fontSizeHeading3, fontWeight: "300", textAlign:'center' }}>
            Prices start from CA$549 for one week package.
          </h3>
        </Row>
        <Row justify="center">
        {offers.map((offer)=>(
            <OfferList {...offer}/>
        ))}
        </Row>
      </div>
    </div>
  );
};

export default WhatWeOffer;
