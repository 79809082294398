import React from 'react';
import { Layout, Row, Col, theme } from 'antd';
import { FacebookOutlined, InstagramOutlined, TwitterOutlined } from '@ant-design/icons';
import logo from "./logo-light.svg";

const { Footer } = Layout;

const AppFooter = () => {
  const { token } = theme.useToken();

  return (
    <Footer
      style={{
        backgroundColor: token.fontColorDark,
        color: 'white',
        padding: '20px 50px',
        textAlign: 'center'
      }}
    >
      <Row justify="space-between" align="middle" style={{ width: '100%', margin: 0 }}>
        <Col xs={24} sm={12} md={6} style={{ textAlign: 'center', marginBottom: '10px' }}>
        <img src={logo} height={80} alt={"settle-in-global-logo"}/>
        </Col>
        <Col xs={24} sm={12} md={6} style={{ textAlign: 'center', marginBottom: '10px' }}>
          <div>
            <p style={{ margin: 0 }}>Email: settleglobal@gmail.com</p>
            <p style={{ margin: 0 }}>Phone: +91 84275 76882</p>
          </div>
        </Col>
        {/* <Col xs={24} sm={12} md={6} style={{ textAlign: 'center', marginBottom: '10px' }}>
          <div>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', marginRight: '10px' }}>
              <InstagramOutlined style={{ fontSize: '24px' }} />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', marginRight: '10px' }}>
              <TwitterOutlined style={{ fontSize: '24px' }} />
            </a>
          </div>
        </Col> */}
      </Row>
      <Row justify="center" align="middle" style={{ marginTop: '20px', width: '100%', margin: 0 }}>
        <Col xs={24} style={{ textAlign: 'center' }}>
          <p style={{ margin: 0 }}>&copy; 2024 Settle In Global. All rights reserved.</p>
        </Col>
      </Row>
    </Footer>
  );
};

export default AppFooter;
