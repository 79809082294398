import React from "react";
import { Layout } from "antd";
import "react-phone-input-2/lib/style.css";
import "../css/Home.css";
import Hero from "./Hero";
import WhatWeOffer from "./WhatWeOffer";
import AppFooter from "./AppFooter";
import Pricing from "./Pricing";
const { Content } = Layout;

const Home = () => {
  return (
    <Layout>
      <Content>
        <section id="home">
          <Hero />
        </section>
        <section id="what_we_offer">
          <WhatWeOffer />
        </section>
        <section id="pricing">
          <Pricing />
        </section>
      </Content>
      <AppFooter />
    </Layout>
  );
};

export default Home;
