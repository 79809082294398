import React from "react";
import { Row, Col, theme } from "antd";
import "../../css/OfferList.css";

const OfferList = ({ icon, offerHeading, offerDetails }) => {
  const { token } = theme.useToken();

  return (
    <Col lg={8} md={12} sm={24} style={{ padding: "10px" }}>
      <Row justify="center">
        <h1
          style={{
            fontSize: '30px',
            textAlign: "center",
            lineHeight: "32px",
          }}
        >
          {offerHeading}
        </h1>
      </Row>
      <Row justify="center">{icon}</Row>
      <Row justify="center">
        <p
          style={{
            fontSize: token.fontSizeP,
            textAlign: "justify",
            lineHeight: "32px",
            padding: '0px 20px',
          }}
        >
          {offerDetails}
        </p>
      </Row>
    </Col>
  );
};

export default OfferList;
