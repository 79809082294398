// src/components/NavBar.jsx
import React, { useState, useEffect } from "react";
import { Menu, Drawer, Button, Row, Col, theme } from "antd";
import {
  HomeOutlined,
  MenuOutlined,
  InfoCircleOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import "../css/Navbar.css";
import logo from "./logo.svg";
import { Link, Events } from "react-scroll";

const NavBar = () => {
  const [current, setCurrent] = useState("home");
  const [visible, setVisible] = useState(false);
  const { token } = theme.useToken();

  const onClick = (e) => {
    setCurrent(e.key);
    setVisible(false);
  };

  const handleSetActive = (to) => {
    setCurrent(to);
  };

  useEffect(() => {
    Events.scrollEvent.register("begin", (to, element) => {
      handleSetActive(to);
    });

    Events.scrollEvent.register("end", (to, element) => {
      handleSetActive(to);
    });

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrent(entry.target.id);
        }
      });
    };

    const observerOptions = {
      threshold: 0.7,
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    const sections = document.querySelectorAll("section");
    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      observer.disconnect();
    };
  }, []);

  const items = [
    {
      key: "home",
      icon: <HomeOutlined />,
      label: (
        <Link
          to="home"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => onClick({ key: "home" })}
          style={{
            fontSize: 16,
            cursor: "pointer",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          Home
        </Link>
      ),
    },
    {
      key: "what_we_offer",
      icon: <InfoCircleOutlined />,
      label: (
        <Link
          to="what_we_offer"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => onClick({ key: "what_we_offer" })}
          style={{
            fontSize: 16,
            cursor: "pointer",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          What We Offer
        </Link>
      ),
    },
    {
      key: "pricing",
      icon: <DollarCircleOutlined />,
      label: (
        <Link
          to="pricing"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => onClick({ key: "pricing" })}
          style={{
            fontSize: 16,
            cursor: "pointer",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          Pricing
        </Link>
      ),
    },
  ];

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Row justify="space-between" className="navbar">
      <Col>
        <NavLink to="/">
          <img src={logo} height={80} alt={"settle-in-global-logo"} />
        </NavLink>
      </Col>
      <Col className="menu" style={{ alignItems: "center", display: "flex" }}>
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
          style={{ width: "500px", backgroundColor: token.fontColorLight }}
        />
      </Col>
      <div className="mobile-menu">
        <Button type="primary" onClick={showDrawer} icon={<MenuOutlined />} />
        <Drawer
          title="Menu"
          placement="right"
          onClose={onClose}
          visible={visible}
        >
          <Menu
            onClick={onClick}
            selectedKeys={[current]}
            mode="vertical"
            items={items}
          />
        </Drawer>
      </div>
    </Row>
  );
};

export default NavBar;
