import React, { useState } from "react";
import { Form, Modal, Input, theme, Row, Col } from "antd";
import PhoneInput from "react-phone-input-2";
import { db, analytics } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import toast from "react-hot-toast";

const isValidPhoneNumber = (rule, value) => {
  if (value && value.length === 12 && value[2] > 5) {
    return Promise.resolve();
  }
  return Promise.reject(
    "Invalid phone number. Please enter a valid phone number."
  );
};

const UserInfoModal = ({
  userInfoModalVisible,
  setUserInfoModalVisible,
  planCode,
}) => {
  const { token } = theme.useToken();
  const [userInfoForm] = Form.useForm();
  const [userInfoSubmitting, setUserInfoSubmitting] = useState(false);

  const userInfoModalCancel = () => {
    setUserInfoModalVisible(false);
    userInfoForm.resetFields();
  };

  const userInfoModalOk = async () => {
    setUserInfoSubmitting(true);
    try {
      const values = await userInfoForm.validateFields(); // Get form values

      // Prepare data to be submitted
      const data = {
        firstName: values.first_name,
        lastName: values.last_name,
        email: values.email,
        mobile: values.mobile,
        city: values.city,
        state: values.state,
        planCode: planCode,
        timestamp: new Date(),
      };

      // Add the data to the Firestore collection
      const collectionRef = collection(db, "pricing_info_requests");
      await addDoc(collectionRef, data);

      // Display success message
      toast.success("Thank you! Our team will reach out to you shortly");

      // Log event
      logEvent(analytics, "get_pricing_clicked", {
        planCode: planCode,
      });

      userInfoModalCancel();
    } catch (error) {
      // Handle errors
      toast.error("There was an error submitting your information.");
    } finally {
      setUserInfoSubmitting(false);
    }
  };

  return (
    <Modal
      title={<p style={{ color: token.fontColorDark }}>Enter your details</p>}
      visible={userInfoModalVisible}
      onOk={userInfoForm.submit}
      okText="Submit"
      onCancel={userInfoModalCancel}
      okButtonProps={{ disabled: userInfoSubmitting }}
      style={{ maxHeight: "60vh", overflow: "hidden" }} // Set max height of the modal
      bodyStyle={{ overflow: "auto" }}
    >
      <div style={{ maxHeight: "calc(60vh - 150px)", overflow: "auto" }}>
        <Form
          form={userInfoForm}
          onFinish={userInfoModalOk}
          style={{ width: "90%" }}
        >
          <Row justify="space-between">
            <Col md={11} sm={24}>
              <Form.Item
                name="first_name"
                label="First Name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  { required: true, message: "Please enter first name!" },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col md={11} sm={24}>
              <Form.Item
                name="last_name"
                label="Last Name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="email"
            label="Email"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              { required: true, message: "Please enter email!" },
              { type: "email", message: "Please enter valid email!" },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            name="mobile"
            label="Mobile Number"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              { required: true, message: "Please enter mobile number!" },
              { validator: isValidPhoneNumber },
            ]}
          >
            <PhoneInput
              country="in"
              countryCodeEditable={false}
              dropdownStyle={{
                color: token.colorPrimary,
              }}
              defaultCountry="in"
            />
          </Form.Item>
          <Row justify="space-between">
            <Col md={11} sm={24}>
              <Form.Item
                name="city"
                label="City"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: "Please enter city name!" }]}
              >
                <Input placeholder="City" />
              </Form.Item>
            </Col>
            <Col md={11} sm={24}>
              <Form.Item
                name="state"
                label="State"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  { required: true, message: "Please enter state name!" },
                ]}
              >
                <Input placeholder="State" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default UserInfoModal;
