import { Button, ConfigProvider, Input, Space, theme } from "antd";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home.jsx";
import NavBar from "./components/NavBar";
import './App.css';
import {Toaster} from "react-hot-toast";

const queryClient = new QueryClient();

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#25424C", // Set your custom primary color here
          fontColorLight: "#FFFEF2",
          fontColorDark: "#25424C",
          iconColorDark: "#E56E38",
          fontSizeHeading1: 40,
          fontSizeHeading3: 20,
          fontSizeP: 16
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <Router>
            <NavBar />
            <Routes>
              <Route exact path="/" element={<Home />} />
            </Routes>
            <Toaster position="bottom-center" toastOptions={{ duration: 2000 }}/>
          </Router>
        </div>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
