import React, { useState } from "react";
import { Row, Input, theme, Button, Modal, Form } from "antd";
import mobileHeroImage from "./mobile-hero.png"; // Importing mobile image
import webHeroImage from "./web-hero.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../css/Home.css";
import { db, analytics } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import toast from "react-hot-toast";
import { isValidPhoneNumber } from "../utils/utils";
import TextArea from "antd/es/input/TextArea";

const Hero = () => {
  const { token } = theme.useToken();
  const [isPhoneInput, setPhoneInput] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [helpRequestSubmitting, setHelpRequestSubmitting] = useState(false);
  const [enterQueryModalVisible, setEnterQueryModalVisible] = useState(false);
  const [queryForm] = Form.useForm();

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const useEmailInput = "Use email instead";
  const useMobileInput = "Use mobile instead";

  const changeInput = () => {
    setPhoneInput(!isPhoneInput);
  };

  const enterQueryModalOk = async () => {
    setHelpRequestSubmitting(true);
    try {

      const values = await queryForm.validateFields();

      const browserInfo = `User Agent: ${navigator.userAgent}`;
      const userValue = isPhoneInput ? { phoneNumber } : { email };

      const data = {
        ...userValue,
        browserInfo,
        query: values.query,
        city: values.city,
        timestamp: new Date(),
      };

      const collectionRef = collection(db, "help_requests");

      await addDoc(collectionRef, data);
      toast.success("Your information has been submitted!");

      setPhoneNumber("91");
      setEmail("");
      
      logEvent(analytics, "get_help_clicked", {
        inputType: isPhoneInput ? "phone" : "email",
        value: isPhoneInput ? phoneNumber : email,
      });
      setEnterQueryModalVisible(false);
      queryForm.resetFields();
    } catch (error) {
      toast.error("There was an error submitting your information.");
    }
    setHelpRequestSubmitting(false);
  };

  const enterQueryModalCancel = () => {
    queryForm.resetFields();
    setEnterQueryModalVisible(false);
  };

  const handleGetHelpClick = () => {
    let errorMessage = "";

    // Validate phone number
    if (isPhoneInput) {
      if (!phoneNumber.trim() || !isValidPhoneNumber(phoneNumber)) {
        errorMessage = "Please enter a valid phone number.";
      }
    } else {
      // Validate email
      if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
        errorMessage = "Please enter a valid email address.";
      }
    }

    if (errorMessage) {
      toast.error(errorMessage);
      setHelpRequestSubmitting(false);
      return;
    }
    setEnterQueryModalVisible(true);
  };

  return (
    <div
      className="background-container"
      style={{
        backgroundImage: `url(${
          window.innerWidth <= 768 ? mobileHeroImage : webHeroImage
        })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: `calc(100svh - 80px)`,
      }}
    >
      <div
        style={{
          zIndex: "2",
          position: "relative",
          color: token.fontColorLight,
          paddingTop: window.innerWidth <= 768 ? "14dvh" : "20dvh",
          margin: "0 20px",
        }}
      >
        <Row justify="center">
          <h1 style={{ fontSize: token.fontSizeHeading1, margin: 0 }}>
            Airport Pickup, Accomodation, Indian Food and more
          </h1>
        </Row>
        <Row justify="center">
          <h3 style={{ fontSize: token.fontSizeHeading3, fontWeight: "300" }}>
            Settle In Calgary hassle-free with our amazing 1 week package
          </h3>
        </Row>
        <Row justify="center">
          <h3
            style={{
              fontSize: token.fontSizeHeading3,
              marginTop: 0,
              fontWeight: "300",
            }}
          >
            Ready for purchase or have any queries? Enter your mobile number
          </h3>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          {isPhoneInput ? (
            <PhoneInput
              country="in"
              value={phoneNumber}
              countryCodeEditable={false}
              disableDropdown={true}
              onChange={handlePhoneNumberChange}
              inputStyle={{
                fontSize: token.fontSizeP,
                width: "100%",
                height: "48px",
              }}
              dropdownStyle={{
                color: token.colorPrimary,
              }}
              defaultCountry="in"
            />
          ) : (
            <Input
              style={{
                fontSize: token.fontSizeP,
                width: "60%",
                maxWidth: "500px",
              }}
              onChange={handleEmailChange}
              placeholder="Enter email"
              autoCapitalize="none"
            />
          )}
          <Button
            type="primary"
            style={{
              fontSize: token.fontSizeP,
              height: "48px",
              marginLeft: "10px",
            }}
            onClick={handleGetHelpClick}
          >
            Get Help {`>`}
          </Button>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button type="text" onClick={changeInput}>
            <span
              style={{
                fontSize: token.fontSizeP,
                color: token.fontColorLight,
                textDecoration: "underline",
              }}
            >
              {isPhoneInput ? useEmailInput : useMobileInput}
            </span>
          </Button>
        </Row>
      </div>
      <Modal
        title="Submit query"
        visible={enterQueryModalVisible}
        onOk={queryForm.submit}
        onCancel={enterQueryModalCancel}
        okText="Submit"
        okButtonProps={{ disabled: helpRequestSubmitting }}
      >
        <Form form={queryForm} onFinish={enterQueryModalOk}>
          <Form.Item
            name="query"
            label="Query"
            labelCol={{span: 24}}
            rules={[{ required: true, message: "Please enter your query" }]}
          >
            <TextArea placeholder="Describe your query" autoSize={{ minRows: 5, maxRows: 8 }}/>
          </Form.Item>
          <Form.Item
            name="city"
            label="City"
            labelCol={{span: 24}}
            rules={[{ required: true, message: "Please enter city you are moving to" }, { type: 'string', message: "Please enter valid city name" }]}
          >
            <Input placeholder="City you are moving to"/>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Hero;
