import React, { useState } from "react";
import { Card, Row, Col, Button, theme } from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UserInfoModal from "./widgets/UserInfoModal";

const pricingData = [
  {
    planCode: 101,
    name: "Basic Plan",
    price: "$549",
    features: [
      "Accomodation in private room",
      "Airport pickup",
      "Limited Customer Support",
      "",
      "",
    ],
    timePeriod: "for one week",
    buttonText: "Get Started",
    planDescription: "The best option if you like to cook",
    isPopular: false,
  },
  {
    planCode: 201,
    name: "Standard Plan",
    price: "$649",
    features: [
      "Accomodation in private room",
      "Meals twice a day",
      "Airport pickup",
      "Customer Support",
      "",
    ],
    timePeriod: "for one week",
    buttonText: "Get Started",
    planDescription: "The best option to start with",
    isPopular: true,
  },
  {
    planCode: 301,
    name: "Premium Plan",
    price: "$1999",
    timePeriod: "for one month",
    features: [
      "Accomodation in private room",
      "Meals twice a day",
      "Airport pickup",
      "Extended Customer Support",
      "",
    ],
    buttonText: "Contact Us",
    planDescription: "The best option to settle in",
    isPopular: false,
  },
];

const PricingCard = ({ plan, priceButtonClicked, token }) => (
  <Card
    title={
      <div style={{ padding: "20px 0px", color: token.fontColorDark }}>
        <div
          style={{
            border: "1px solid grey",
            borderRadius: "30px",
            padding: "10px",
            display: "inline-block",
          }}
        >
          {plan.name}
        </div>
        <Row>
          <h1 style={{ fontSize: "40px", margin: 0 }}>
            {plan.price}
            <span
              style={{
                fontSize: "20px",
                marginLeft: "10px",
                fontWeight: "300",
              }}
            >
              {plan.timePeriod}
            </span>
          </h1>
        </Row>
        <Row>
          <p style={{ fontSize: "16px", margin: 0, fontWeight: "300" }}>
            {plan.planDescription}
          </p>
        </Row>
      </div>
    }
    style={{
      minHeight: "500px",
      width: "100%",
      minWidth: "300px",
      marginBottom: "40px",
      borderColor: plan.isPopular ? token.fontColorDark : "grey",
      borderStyle: "solid",
      borderWidth: plan.isPopular ? "3px" : "1px",
      boxShadow: "0 8px 16px rgba(0,20,30, 1)",
      color: token.fontColorDark,
      backgroundColor: token.fontColorLight,
    }}
  >
    <div>
      <ul style={{ listStyle: "none", padding: 0 }}>
        {plan.features.map((feature, index) => (
          <li key={index} style={{ margin: "10px 0" }}>
            <Row>
              {feature ? (
                <>
                  <Col span={2}>
                    <CheckCircleIcon
                      style={{ width: "30px", height: "auto" }}
                    />
                  </Col>
                  <Col
                    span={18}
                    style={{ marginLeft: "10px", fontSize: "18px" }}
                  >
                    {feature}
                  </Col>
                </>
              ) : (
                <>
                  <Col span={2}>
                    <div style={{ width: "30px", height: "auto" }} />
                  </Col>
                  <Col
                    span={18}
                    style={{ marginLeft: "10px", fontSize: "18px" }}
                  >
                    <span>&nbsp;</span>
                  </Col>
                </>
              )}
            </Row>
          </li>
        ))}
      </ul>
    </div>
    <div style={{ position: "absolute", bottom: "20px", left: "50%", transform: "translateX(-50%)" }}>
      <Button
        onClick={() => priceButtonClicked(plan.planCode)}
        style={{
          width: "200px",
          boxShadow: "0 2px 4px rgba(10,20,30, 0.5)",
          backgroundColor: plan.isPopular
            ? token.fontColorDark
            : token.fontColorLight,
          color: plan.isPopular ? token.fontColorLight : token.fontColorDark,
        }}
      >
        {plan.buttonText}
      </Button>
    </div>
  </Card>
);

const Pricing = () => {
  const { token } = theme.useToken();
  const [userInfoModalVisible, setUserInfoModalVisible] = useState(false);
  const [planCode, setPlanCode] = useState(0)

  const priceButtonClicked = (planCode) => {
    setUserInfoModalVisible(true);
    setPlanCode(planCode)
  };

  return (
    <>
      <div
        style={{
          backgroundColor: token.fontColorDark,
          padding: "5% 0px",
          textAlign: "left",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              border: "1px solid #FFFEF2",
              borderRadius: "30px",
              padding: "10px",
              display: "inline-block",
              fontWeight: "700",
              color: token.fontColorLight,
            }}
          >
            Pricing
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Col
            style={{
              width: "800px",
              fontSize: "30px",
              textAlign: "center",
              color: token.fontColorLight,
            }}
          >
            <h1 style={{ marginBottom: 0 }}>
              Choose the plan that fits your needs
            </h1>
          </Col>
        </div>
        <div
          style={{
            width: "100%",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            paddingBottom: "50px",
            paddingTop: "20px",
          }}
        >
          <h1
            style={{
              maxWidth: "800px",
              width: '90%',
              color: token.fontColorLight,
              fontWeight: "300",
            }}
          >
            These plans offer diverse options to cater to your needs when you
            are planning to move abroad
          </h1>
        </div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Row justify="space-evenly" style={{ width: "80%" }}>
            {pricingData.map((plan, index) => (
              <Col lg={7} md={16} key={index}>
                <PricingCard
                  plan={plan}
                  token={token}
                  priceButtonClicked={priceButtonClicked}
                />
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <UserInfoModal
        userInfoModalVisible={userInfoModalVisible}
        setUserInfoModalVisible={setUserInfoModalVisible}
        planCode={planCode}
      />
    </>
  );
};

export default Pricing;
